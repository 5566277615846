.section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .project-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  
  .project-item {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .project-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .project-details h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .project-details p {
    margin-bottom: 10px;
  }
  
  .project-details ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 10px;
  }
  
  .project-details ul li {
    display: inline-block;
    margin-right: 10px;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .project-links a {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .project-links a:hover {
    background-color: #0056b3;
  }
  
  @media (min-width: 576px) {
    .project-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 768px) {
    .project-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  

  .primaryText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .project-list{
    margin-top: 100px;
  }