@import '../../styles/contants.scss';



.wrapper{
    background: white;
    .container{
        gap: 2rem;
        @media (max-width: $sm) {
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
        }
        .left{
            flex: 3;
            >*{
                display: block;
            }
            >:nth-child(2)
            {
                margin-top: 3rem;

                a{
                    text-decoration: underline;
                    color: orange;
                }
            }
        }


        .right{
            flex: 1;
            .info{
                margin-bottom: 1rem;
                >*{
                    display: block;
                }
            }
            .menu{
                @media (max-width: $sm) {
                    flex-direction: row;
                    flex-wrap: wrap;
                }
                display: flex;
                flex-direction: column;
                gap: 2rem;
                list-style: none;
                li{
                    cursor: pointer;
                    &:hover{
                        color: $secondary
                    }
                }
            }
        }
    }
}



.footer {
    padding: 0 0 50px 0;
    // background-image: url('../../../src//components/assets/img/footer-bg.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .newsletter-bx {
    background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  
    margin-top: -122px;
  }
  
  /* .newsletter-bx {
    background: #FFFFFF;
    border-radius: 55px;
    color: #121212;
    padding: 85px 125px;
 
    margin-bottom: 80px;
    margin-top: -122px;
    
   
  } */
  .newsletter-bx h3 {
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.2em;
  }
  .new-email-bx {
    background: #fff;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
  }
  .new-email-bx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
  }
  .new-email-bx::after {
    content: "";
    background: #fff;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .new-email-bx input {
    width: 100%;
    color: #121212;
    font-weight: 500;
    background: transparent;
    border: 0;
    padding: 0 15px;
  }
  .new-email-bx button {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    padding: 20px 65px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 18px;
  }
  /* .footer img {
    width: 26%;
  }
  .footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 50px;
    align-items: center;
  } */

  /* .secondaryText.colz-icon{
    color: white;
    padding: 8px;
    padding-left: 0;
} */

/* .socialIcons {
    color: white;
    padding: 8px;
    padding-left: 0;
    align-items: center;
    margin-left: 80px;
} */


.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.social-icons a {
    display: inline-block;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    font-size: 24px;
    color: white;
  }
  
  .social-icons a:hover {
    transform: scale(1.2); /* Adjust the scale value as per your desired zoom level */
  }
  
  .copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }
  

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .getInTouch {
    margin-bottom: 1rem;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .emailInput {
    padding: 0.5rem;
    margin-right: 1rem;
  }
  
  .submitButton {
    padding: 0.5rem 1rem;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .social {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .socialIcon {
    margin: 0 0.5rem;
    color: #333;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .socialIcon:hover {
    color: #666;
  }
  
  .copyRight {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
  }

  .messageInput {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  



